<template>
    <div id = "SentenceAllExplanation">
    <Header/>
    <CommonAlert1 ref="CommonAlert1" :title="title"  :btnnameup="btnNameUp" :content="content"/>
    <body class=" bg-white">
<!-- header -->
    <nav class="navbar bg-white fixed-top nicole-noshadow">
    <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" >
        <img class="avatar avatar-sm" src="assets/img/icon-back-black.png" @click="onBack">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
        學習更多
        </a>             
        <a class="navbar-brand font-weight-bold text-smm me-0 text-right col-4" > 
        <img class="avatar rounded-0 avatar-sm" src="assets/img/ic_saved.png" v-show="data_lesson_sentence.is_collect == 'Y'" @click="sendUnCollect">    
        <img class="avatar rounded-0 avatar-sm" src="assets/img/ic_unsave.png" v-show="data_lesson_sentence.is_collect != 'Y'" @click="sendCollect">    
        </a> 
    </div>
    </nav>    
    <!-- End header -->
    <div class="nicolespacetop"></div>
    <!-- End header -->
        <div class="container">
            <!-- eslint-disable vue/no-v-html -->
            <h5 v-bind:class="['fw-bolder ' ,type_text_22 , ' text-g  text-dark my-2']" v-html="data_lesson_sentence.name"></h5>
            <h5 v-bind:class="['text-gray ' ,type_text_s]" v-html="data_lesson_sentence.description"></h5>
            <hr class="border-1 text-gray">
            <div class="mb-4">
                <h5 v-bind:class="['text-dark text-bold  mt-2 mb-3 ' , type_text_n]">延伸學習</h5> 
                <div class="mb-4">
                    <h5 v-bind:class="['text-gray ' , type_text_s]" v-html="getSentenceExplanation()"></h5>
                </div>
            </div>
            <!--eslint-enable-->

            <!--
            <h5 class="fw-bolder text-card-xl text-g  text-dark my-2">spring to life</h5>
            <h5 class="text-gray text-s">恢復生機；突然活躍、忙碌起來</h5>
            <hr class="border-1 text-gray">
            <div class="mb-4">
                <h5 class="text-dark text-bold  mt-2 mb-3 text-n">延伸學習</h5> 
                <div class="mb-4">
                    <h5 class="text-dark text-s text-simbold">WSJ’s Joanna Stern reviews the hearings of both to explore what cigarette regulation </h5>
                    <h5 class="text-gray text-s">那狗正在睡覺, 聽到有人喚它, 猛地驚醒過來。</h5>
                </div>
            </div>
            <div class="mb-4">
            <h5 class="text-dark text-bold  mt-2 mb-3 text-n">例句</h5>
                <div class="mb-4">
                    <h5 class="text-dark text-s text-simbold">Members of Congress have <span class="text-success text-bolder">likened Facebook</span> and Instagram’s tactics to that of the tobacco industry.</h5>
                    <h5 class="text-gray text-s">那狗正在睡覺, 聽到有人喚它, 猛地驚醒過來。</h5>
                </div>             
                <div class="mb-4">
                    <h5 class="text-dark text-s text-simbold">Members of Congress have <span class="text-success text-bolder">likened Facebook</span> and Instagram’s tactics to that of the tobacco industry.</h5>
                    <h5 class="text-gray text-s">那狗正在睡覺, 聽到有人喚它, 猛地驚醒過來。</h5>
                </div>        
            </div>        
            <div class="mb-4">
            <h5 class="text-dark text-bold mt-2 mb-3 text-n">類似片語</h5>
                <div class="mb-4">
                    <div class="d-flex">
                    <h5 class="text-success text-bolder text-s">springs to life</h5>
                    <h5 class="text-dark text-simbold text-s ms-2">躍入腦海</h5>
                    </div>    
                    <h5 class="text-gray text-s text-simbold">Facebook said it is shutting down its facial-recognition system in the coming weeks</h5>
                </div>            
                <div class="mb-4">
                    <div class="d-flex">
                    <h5 class="text-success text-bolder text-s">springs to life</h5>
                    <h5 class="text-dark text-simbold text-s ms-2">躍入腦海</h5>
                    </div>    
                    <h5 class="text-gray text-s text-simbold">Facebook said it is shutting down its facial-recognition system in the coming weeks</h5>
                </div>
            </div>
            -->
        </div>   
        <div class="nicolespace"></div>
        <div class="nicolespace"></div>
</body>

</div>
</template>>

<script>
import Header from '@/components/Header.vue'
import CommonAlert1 from '@/components/CommonAlert1.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";

export default {
    name: 'SentenceAllExplanation',
    components: {
        Header,
        CommonAlert1,
    },
    data()
    {
        return{
            // api setting
            setDebugMode:false,
            apiDebug: false,
            debugMode:false,
            apiName: "",
            //
            lssid : "",
            data_lesson_sentence:{},

            //alert
            title : "",
            content:"",
            btnNameUp:"",
            type_text_n : "text-n",
            type_text_22 : "text-22",
            type_text_s : "text-s",
        }
    },
    methods:
    {
        setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
        onBack()
        {
            console.log("back");
            var backUrl = "";
            var native = "";
            var data = {};

            if (this.$route.query && this.$route.query.backUrl) {
              data = this.$route.query;
              if (this.$route.query.backUrl != "" && this.$route.query.backUrl != undefined ){
                backUrl = this.$route.query.backUrl;
              }
            }
            if (this.$route.params && this.$route.params.backUrl) {
              data = this.$route.params;
              if (this.$route.params.backUrl != "" && this.$route.params.backUrl != undefined ){
                backUrl = this.$route.params.backUrl;
              }
            }
            if (this.$route.query && this.$route.query.native) {
              native = this.$route.query.native
            }
            if (this.$route.params && this.$route.params.native) {
              native = this.$route.params.native
            }



            if(native != ""){
              if(native.indexOf("teacher_explain") > -1){
                  this.$JsBridge.callHandler('openTeacherExplain',JSON.stringify({
                      backUrl: backUrl,
                      mode: "vue",
                      type: "back",
                  }), this.openTeacherExplainCallback);
              }
            } else {
                this.$router.push(
                {
                    name : this.$route.params.back,//'EverybodyLearn',
                    params : {
                    back: this.$route.name,
                    lssid : this.lssid,
                    //view : this.view,
                    }
                });
            }



            
        },
        openTeacherExplainCallback(){

        },
        alert(title,message)
        {
            this.title = title;
            this.content = message;
            this.btnNameUp = "確定";

            this.$refs.CommonAlert1.onShow();
        },
        sendGetMainData()
        {
            this.apiName = "page_sentence_all_explanation_get";
            oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid,lssid:this.lssid}).then(
            res =>
                {
                    if(res.message != undefined)
                    {
                        this.alert("",res.message);
                        return;
                    }                    


                    if(res != null && res.lesson_sentence != null){
                        var lesson_sentence = res.lesson_sentence;
                        if(lesson_sentence.length > 0)
                        {
                            this.data_lesson_sentence = lesson_sentence[0];
                        }
                    }

                }
            )
            .catch((err)=> {
                oltConsole.log(err);
            });
        },
        sendCollect()
        {
            this.data_lesson_sentence.is_collect = "Y";
            this.apiName = "page_sentence_all_explanation_collect";
            oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid,lssid:this.lssid}).then(
            res =>
                {
                    if(res != null && res.history_user_collect_lesson_sentence != null){
                        var history_user_collect_lesson_sentence = res.history_user_collect_lesson_sentence;
                        if(history_user_collect_lesson_sentence.length > 0)
                        {
                            this.data_lesson_sentence.is_collect = history_user_collect_lesson_sentence[0].switch;
                        }
                    }
                }
            )
            .catch((err)=> {
                oltConsole.log(err);
            });
        },
        sendUnCollect()
        {
            this.data_lesson_sentence.is_collect = "N";
            this.apiName = "page_sentence_all_explanation_uncollect";
            oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid,lssid:this.lssid}).then(
            res =>
                {
                    if(res != null && res.history_user_collect_lesson_sentence != null){
                        var history_user_collect_lesson_sentence = res.history_user_collect_lesson_sentence;
                        if(history_user_collect_lesson_sentence.length > 0)
                        {
                            this.data_lesson_sentence.is_collect = history_user_collect_lesson_sentence[0].switch;
                        }
                    }
                }
            )
            .catch((err)=> {
                oltConsole.log(err);
            });
        },
        changeFontSize(fontSize)
        {
            oltConsole.log("font_size : " + fontSize);

            switch(fontSize)
            {
                //大
                case "L":
                    this.type_text_n = "text-n-l";
                    this.type_text_22 = "text-22-l";
                    this.type_text_s = "text-s-l";
                break;

                //中
                case "N":
                    this.type_text_n = "text-n";
                    this.type_text_22 = "text-22";
                    this.type_text_s = "text-s";
                    break;

                //小
                case "S":
                    this.type_text_n = "text-n-s";
                    this.type_text_22 = "text-22-s";
                    this.type_text_s = "text-s-s";
                    break;

                default:
                    this.type_text_n = "text-n";
                    this.type_text_22 = "text-22";
                    this.type_text_s = "text-s";
                    break;
            }
        },
        getSentenceExplanation(){
            var str = "";
            if(this.data_lesson_sentence != null && this.data_lesson_sentence.explanation != null && this.data_lesson_sentence.explanation !=  "" && this.data_lesson_sentence.explanation != undefined){
                str = this.data_lesson_sentence.explanation.replace(/<p>/g,`<p class="${this.type_text_s}">`);
            }
            return str;
        },

    },
    created()
    {
        var fontSize = "";
        if(this.$route.query != undefined)
        {
            this.lssid = this.$route.query.lssid;
            fontSize = this.$route.query.fontSize;
        }


        if(this.lssid == undefined && this.$route.params != undefined)
        {
            this.lssid = this.$route.params.lssid;
        }

        if(fontSize == undefined && this.$route.params != undefined)
        {
            fontSize = this.$route.params.fontSize;
        }

        if (this.$JsBridge.isAndroid) {
            this.setOnBack();
        }

        //調整大小
        this.changeFontSize(fontSize);

        this.sendGetMainData();
    }
};

</script>